<template>
    <div class="gameRules">
        <div class="backBtn" @click="$router.go(-1)">
            <svg-icon iconClass="back"></svg-icon>
        </div>
        <img class="gameRuleIamge" :src="gameRule" alt="">
    </div>
</template>

<script>
    export default {
        data() {
            return {
                gameRule: require("@/assets/png/gameRules.jpg"),
            }
        }
    }
</script>

<style lang="scss" scoped>
    .gameRules {
        width: 100%;
        height: 100%;
        overflow-y: auto;

        .backBtn {
            position: fixed;
            top: 11px;
            left: 16px;
            width: 36px;
            height: 36px;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #36303060;
            border-radius: 50%;

            >svg {
                font-size: 22px;
            }
        }

        .gameRuleIamge {
            width: 100%;
            object-fit: fill;
            // height:100%;
        }
    }
</style>